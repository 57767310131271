@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;800&display=swap");
body {
  font-family: 'Open Sans', sans-serif;
}

* {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: #C65E13;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

ul {
  list-style: none;
}

h2 {
  color: #292727;
  font-weight: 600;
}

button {
  padding: 0.3em 1em;
  outline: none;
  border: none;
  border-radius: 0.3em;
  font-size: 1.03em;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  cursor: pointer;
}

button:hover {
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
}

.prime {
  background-color: #C65E13;
  color: white;
}

.dark {
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
}

.dark:hover {
  background-color: #C65E13;
  color: white;
}

.Header .container {
  background-color: #EEE6E6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 1em 4em;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 800px) {
  .Header .container {
    padding: 1em 1em;
  }
}

.Header .container .logo {
  max-height: 50px;
}

.Header .container .menu label {
  font-size: 2em;
  display: none;
}

@media (max-width: 800px) {
  .Header .container .menu label {
    display: inline-block;
  }
}

.Header .container .menu input {
  display: none;
}

.Header .container .menu .mobilemenu {
  display: block !important;
  position: absolute;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 800;
  padding: 2em;
  border-radius: 0.3em;
}

.Header .container .menu .mobilemenu a {
  color: white;
  margin-bottom: 1em;
}

.Header .container .menu .mobilemenu li {
  margin-bottom: 2em;
}

.Header .container ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5em;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 800px) {
  .Header .container ul {
    display: none !important;
  }
}

.Header .container ul a {
  color: rgba(0, 0, 0, 0.7);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  padding: 0.3em 0.5em;
}

.Header .container ul a:hover {
  color: white;
  background-color: black;
  border-radius: 0.3em;
}

.Header .container ul img {
  width: 16px;
  height: auto;
  margin-right: 0.3em;
}

.Landing .outer {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 999;
  width: 100vw;
  height: 100vh;
}

.Landing .outer .img {
  -webkit-animation: sizein 200ms linear;
          animation: sizein 200ms linear;
}

.Landing .outer .img img {
  max-width: 90%;
  max-height: 90%;
  border-radius: 0.3em;
}

@media (max-width: 800px) {
  .Landing .outer .img img {
    max-width: 100%;
    max-height: 100%;
  }
}

.Landing .hero {
  position: relative;
  height: 600px;
  background-repeat: no-repeat;
  -webkit-animation-name: MOVE-BG;
          animation-name: MOVE-BG;
  -webkit-animation-duration: 10s;
          animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  background-size: 120% auto;
}

@media (max-width: 800px) {
  .Landing .hero {
    height: 250px !important;
    background-size: 100% 100%;
  }
}

.Landing .hero .content {
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
  height: 600px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 800px) {
  .Landing .hero .content {
    height: 250px !important;
  }
}

.Landing .hero .content h1 {
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 0.5em;
}

.Landing .hero .content .yazi {
  color: rgba(255, 255, 255, 0.6);
  max-width: 800px;
  text-align: center;
  line-height: 1.2em;
}

.Landing .hero .content .buttons {
  margin-top: 1em;
}

.Landing .hero .content .buttons button + button {
  margin-left: 1em;
}

.Landing .banners {
  padding: 1em 4em;
}

@media (max-width: 800px) {
  .Landing .banners {
    padding: 1em 1em;
  }
}

.Landing .banners .glider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Landing .banners .glider .glider-track {
  min-width: 100%;
}

.Landing .banners h2 {
  margin-top: 1em;
  margin-bottom: 1em;
}

.Landing .banners .banner {
  width: 170px;
  background-color: #F6F6F6;
  padding: 0.9em;
  border-radius: 0.3em;
  margin-right: 10%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #433C3C;
  border-radius: 0.6em;
  -webkit-box-shadow: 1px 0 3px rgba(0, 0, 0, 0.2);
          box-shadow: 1px 0 3px rgba(0, 0, 0, 0.2);
  font-size: 0.8em;
}

.Landing .banners .banner img {
  max-width: 64px;
  margin-bottom: 0.5em;
}

.Landing .bolge {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 1em 4em;
  margin-top: 2em;
}

@media (max-width: 800px) {
  .Landing .bolge {
    padding: 1em 1em;
  }
}

.Landing .bolge h2 {
  color: rgba(255, 255, 255, 0.8);
}

.Landing .bolge .video-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media (max-width: 800px) {
  .Landing .bolge .video-container {
    margin-top: 1em;
  }
}

.Landing .bolge .video-container video {
  max-width: 70%;
}

@media (max-width: 800px) {
  .Landing .bolge .video-container video {
    max-width: 95%;
  }
}

.Landing .proje {
  background-color: #E5E8E3;
  padding: 3em 4em;
  display: -ms-grid;
  display: grid;
      grid-template-areas: "title title" "side text";
  padding-bottom: 2em;
}

@media (max-width: 800px) {
  .Landing .proje {
    padding: 3em 1em;
        grid-template-areas: "title title" "text text";
  }
}

.Landing .proje h2 {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: title;
  margin-bottom: 1em;
}

.Landing .proje .yonetici {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-area: side;
}

@media (max-width: 800px) {
  .Landing .proje .yonetici {
    display: none;
  }
}

.Landing .proje .yonetici img {
  width: 160px;
  height: 160px;
  border-radius: 80px;
  margin-right: 4em;
}

.Landing .proje .text {
  -ms-grid-row: 2;
  -ms-grid-column: 2;
  grid-area: text;
}

.Landing .proje .parts {
  color: #524D4D;
  line-height: 1.8em;
}

.Landing .proje .parts div + div {
  margin-top: 1.5em;
}

.Landing .proje .devami {
  text-align: right;
  margin-top: 1em;
}

.Footer {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.99);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.8em 4em;
}

@media (max-width: 800px) {
  .Footer {
    padding: 0.8em 1em;
    display: block;
  }
  .Footer div + div {
    margin-top: 1em;
  }
  .Footer div {
    text-align: center;
  }
}

.Footer .contact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 800px) {
  .Footer .contact {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

.Footer .contact img {
  max-width: 32px;
}

.Galeri {
  padding: 1em 4em;
}

@media (max-width: 800px) {
  .Galeri {
    padding: 1em 1em;
  }
}

.Galeri .images {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 1em;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 1em;
}

.Galeri .images .image img {
  width: 250px;
  height: 250px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 0.3em;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  cursor: pointer;
}

@media (max-width: 800px) {
  .Galeri .images .image img {
    width: 120px;
    height: 120px;
  }
}

.Galeri .images .image img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

@-webkit-keyframes MOVE-BG {
  from {
    background-position: 0% 0%;
  }
  to {
    background-position: 25% 10%;
  }
}

@keyframes MOVE-BG {
  from {
    background-position: 0% 0%;
  }
  to {
    background-position: 25% 10%;
  }
}

@-webkit-keyframes sizein {
  0% {
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sizein {
  0% {
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
