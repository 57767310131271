@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;800&display=swap');

$side-padding: 4em;
$mobile-padding: 1em;
$accent1: #C65E13;
$mobile: 800px;

@mixin mobile {
    @media (max-width: #{$mobile}) {
        @content;
    }
}

body {
    font-family: 'Open Sans', sans-serif;
}

* {
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
    color: $accent1;
    transition: all 300ms ease;
}

ul {
    list-style: none;
}

h2 {
    color: #292727;
    font-weight: 600;
}

button {
    padding: 0.3em 1em;
    outline: none;
    border: none;
    border-radius: 0.3em;
    font-size: 1.03em;
    transition: all 300ms ease;
    cursor: pointer;
}

button:hover {
    background-color: rgba(black, 0.9);
    color: white;
}

.prime {
    background-color: $accent1;
    color: white;
}

.dark {
    background-color: rgba(black, 0.9);
    color: white;

    &:hover {
        background-color: $accent1;
        color: white;
    }
}

.Header {
    .container {
        background-color: #EEE6E6;
        display: flex;
        justify-content: space-between;
        padding: 1em $side-padding;
        align-items: center;

        @include mobile() {
            padding: 1em $mobile-padding;
        }

        .logo {
            max-height: 50px;

        }

        .menu {
            label {
                font-size: 2em;
                display: none;
                @include mobile() {
                    display: inline-block;
                }
            }

            input {
                display: none;
            }

            .mobilemenu {
                display: block !important;
                position: absolute;
                right: 0px;
                background-color: rgba(black, 0.8);
                z-index: 800;
                padding: 2em;
                border-radius: 0.3em;

                a {
                    color: white;
                    margin-bottom: 1em;
                }

                li {
                    margin-bottom: 2em;
                }
            }
        }

        ul {
            display: flex;
            gap: 0.5em;
            align-items: center;

            @include mobile() {
                display: none !important;
            }

            a {
                color: rgba(0, 0, 0, 0.7);
                transition: all 300ms ease;
                padding: 0.3em 0.5em;

                &:hover {
                    color: white;
                    background-color: black;
                    border-radius: 0.3em;
                }
            }

            img {
                width: 16px;
                height: auto;
                margin-right: 0.3em;
            }

        }
    }

}

.Landing {
    .outer {
        position: fixed;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
        width: 100vw;
        height: 100vh;

        

        .img {
            animation: sizein 200ms linear;

            img {
                max-width: 90%;
                max-height: 90%;
                border-radius: 0.3em;

                @include mobile() {
                    max-width: 100%;
                    max-height: 100%;
                }

            }
        }
    }

    .hero {
        position: relative;
        height: 600px;
        background-repeat: no-repeat;
        animation-name: MOVE-BG;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-fill-mode: backwards;
        animation-direction: alternate;
        background-size: 120% auto;

        @include mobile {
            height: 250px !important;
            background-size: 100% 100%;
        }

        .content {
            z-index: 1;
            background-color: rgba(0, 0, 0, 0.6);
            height: 600px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @include mobile {
                height: 250px !important;
            }

            h1 {
                color: rgba(white, 0.9);
                margin-bottom: 0.5em;
            }

            .yazi {
                color: rgba(white, 0.6);
                max-width: 800px;
                text-align: center;
                line-height: 1.2em;
            }

            .buttons {
                margin-top: 1em;

                button+button {
                    margin-left: 1em;
                }
            }
        }
    }

    .banners {
        padding: 1em $side-padding;

        @include mobile() {
            padding: 1em $mobile-padding;
        }

        .glider {
            display: flex;
            justify-content: center;
            align-items: center;

            .glider-track {
                min-width: 100%;
            }
        }

        h2 {
            margin-top: 1em;
            margin-bottom: 1em;
        }

        .banner {
            width: 170px;
            background-color: #F6F6F6;
            padding: 0.9em;
            border-radius: 0.3em;
            margin-right: 10%;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #433C3C;
            border-radius: 0.6em;
            box-shadow: 1px 0 3px rgba(black, 0.2);
            font-size: 0.8em;


            img {
                max-width: 64px;
                margin-bottom: 0.5em;
            }
        }
    }

    .bolge {
        background-color: rgba(black, 0.8);
        padding: 1em $side-padding;
        margin-top: 2em;

        @include mobile() {
            padding: 1em $mobile-padding;
        }

        h2 {
            color: rgba(white, 0.8)
        }

        .video-container {
            display: flex;
            justify-content: center;
            @include mobile() {
                margin-top: 1em;
            }
    

            video {
                max-width: 70%;
                @include mobile() {
                    max-width: 95%;
                }
        
            }
        }


    }

    .proje {
        background-color: #E5E8E3;
        padding: 3em $side-padding;
        display: grid;
        grid-template-areas:
            "title title"
            "side text";
        padding-bottom: 2em;

        @include mobile() {
            padding: 3em $mobile-padding;
            grid-template-areas:
            "title title"
            "text text";
        }


        h2 {
            grid-area: title;
            margin-bottom: 1em;
        }

        .yonetici {
            grid-area: side;
            
            @include mobile() {
                display: none;
            }
            img {
                $is: 160px;
                width: $is;
                height: $is;
                border-radius: $is /2;
                margin-right: 4em;
            }
        }

        .text {
            grid-area: text;
        }

        .parts {

            color: #524D4D;
            line-height: 1.8em;

            div+div {
                margin-top: 1.5em;
            }
        }

        .devami {
            text-align: right;
            margin-top: 1em;
        }
    }
}


.Footer {
    background-color: #212121;
    color: rgba(white, 0.99);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8em $side-padding;

    @include mobile() {
        padding: 0.8em $mobile-padding;
        display: block;
        div + div {
            margin-top: 1em;
        }
        div {
            text-align: center;
        }
    }

    .contact {

        display: flex;
        align-items: center;

        @include mobile() {
            justify-content: center;
        }

        img {
            max-width: 32px;
        }
    }
}

.Galeri {
    padding: 1em $side-padding;

    @include mobile() {
        padding: 1em $mobile-padding;
    }

    .images {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 1em;
        justify-content: center;
        margin-top: 1em;

        .image {
            img {
                width: 250px;
                height: 250px;
                object-fit: cover;
                border-radius: 0.3em;
                transition: all 300ms ease;
                cursor: pointer;

                @include mobile() {
                    width: 120px;
                    height: 120px;
                }

                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }


}


@keyframes MOVE-BG {
    from {
        background-position: 0% 0%
    }

    to {
        background-position: 25% 10%
    }
}

@keyframes sizein {
    0% {
        transform: scale(0.3);
    }

    100% {
        transform: scale(1.0);
    }
}